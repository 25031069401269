





















































































import { Component, Vue } from 'vue-property-decorator';
import HomePage from '@/api/home/homePage';
import DateUtil from '@/utils/DateUtil';
import LeftTree from './components/left-tree.vue';
import location from '@/views/flow-list/components/location.vue';
import { getUserlist } from '@/api/system/PersonApi';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'FrontWaitList',
  components: {
    LeftTree,
    location
  }
})
export default class FrontPlaceholder extends Vue {
  tableLoading: boolean = false;
  list: any[] = [];
  homePage: HomePage = new HomePage();
  searchListData: any = {
    title: '',
    userId: ''
  };
  param: any = {
    applicationIds: [],
    processCode: '',
    page: {
      pageIndex: 1,
      pageSize: 50
    }
  };

  personList: any = [];
  showBack: boolean = false;
  // 列表数据总条数
  total: number = 0;

  pageHeight: string = '100%';

  created() {
    if (this.$route.path == '/waitListView') {
      this.pageHeight = '100%';
    }

    let query = this.$route.query.showback;
    if (query == '1') {
      this.showBack = true;
    }
  }

  mounted() {
    this.getList();
    this.getPersonListFn();
    // 获取语言数据
  }
  // 获取搜索项目-人员列表
  getPersonListFn() {
    getUserlist().then((res: any) => {
      if (res.code == '1') {
        this.personList = res.data;
      }
    });
  }

  changePage(page) {
    this.param.page.pageIndex = page;
    this.getList();
  }

  changePageSize(pageSize) {
    this.param.page.pageSize = pageSize;
    this.getList();
  }

  openApprove(row, seeType = 'form') {
    if (row.processId == 'JUMP') {
      let url: string = row.jumpUrl + '&token=' + TokenUtil.getToken();
      window.open(url, '_blank');
    } else {
      let id = row.id;
      // 待办
      let routeUrl: any = '';
      // 如果是同一个公司
      if (row.performer == UserUtil.getUser().id) {
        routeUrl = this.$router.resolve({ path: '/front2/undo', query: { id, seeType } });
        // 跳转 默认打开新窗口
        window.open(routeUrl.href, '_blank');
      } else {
        // 不同
        routeUrl =
          process.env.VUE_APP_SERVER_CODE +
          'auth/switchAccountByprocess?clientId=' +
          process.env.VUE_APP_CLIENT_ID +
          '&companyId=' +
          row.tenantId +
          '&id=' +
          id +
          '&token=' +
          TokenUtil.getToken() +
          '&seeType=' +
          seeType + '&frontType=1';
        // 跳转 默认打开新窗口
        window.open(routeUrl, '_blank');
      }
    }
  }

  // 处理返回数据
  handleResponse(res: any): void {
    this.list = [];
    this.total = res.data.total;
    this.list = res.data.list.map(node => {
      let d: Date;

      d = DateUtil.parse(node.startDate);
      node.name = node.startUserName;

      let today = new Date();
      if (today.getMonth() === d.getMonth() && today.getDate() === d.getDate() && today.getFullYear() === d.getFullYear()) {
        node.today = true;
      } else {
        node.today = false;
      }
      node.formatedDate = DateUtil.format(d, 'yyyy-MM-dd HH:mm');
      return node;
    });
  }

  getListData(type): void {
    this.param.page.pageIndex = 1;
    this.param.page.pageSize = 50;
    this.getList();
  }
  // 点击搜索
  searchList(sonSearchParams) {
    if (sonSearchParams.applicationIds) {
      this.param.applicationIds = sonSearchParams.applicationIds;
      this.param.processCode = sonSearchParams.processCode;
    }
    this.param = Object.assign(this.param, this.searchListData);
    this.param.page.pageIndex = 1;
    this.getList();
  }
  // 重置
  restSearch() {
    this.param.page.pageIndex = 1;
    this.param.applicationIds = [];
    this.param.processCode = '';
    this.param.title = '';
    this.param.userId = '';
    this.searchListData = {};

    this.getList();
    (this.$refs.leftTree as any).clearCurrentKey();
  }

  // 获取左侧列表
  getList(fnparm?: any): void {
    if (fnparm) {
      this.homePage.getRuntime(fnparm).then(this.handleResponse);
    } else {
      this.homePage.getRuntime(this.param).then(this.handleResponse);
    }
  }

  goBack() {
    window.history.go(-1);
  }
}
